import React from 'react';
import { useIntl } from 'react-intl';
import FeaturedCard from './FeaturedCard';
import LargestFeaturedCard from './LargestFeaturedCard';
import tw from 'twin.macro';
import { FeatureCardType } from './type';

interface FeaturedArticleProps {
  cardList: FeatureCardType[];
  pageId: string;
}
const FeaturedCardContainer = tw.div`
 grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-6 lg:gap-10 items-center
`;

const Heading = tw.p`
  text-[20px] text-base_text mb-6
`;

const ComponentWrapper = tw.div`
  p-[30px] lg:px-0
`;

const SmallCardListContainer = tw.div`
 flex flex-col justify-between lg:justify-start gap-5 items-center
`;

const FeaturedArticle = ({ cardList, pageId }: FeaturedArticleProps) => {
  const { formatMessage } = useIntl();

  return (
    <ComponentWrapper>
      <Heading> {formatMessage({ id: 'featuredArticle.heading' })}</Heading>

      <FeaturedCardContainer>
        {cardList?.[0] && (
          <LargestFeaturedCard {...cardList[0]} pageId={pageId} />
        )}

        <SmallCardListContainer>
          {cardList?.slice(1, 4)?.map(item => (
            <FeaturedCard key={item.slug} {...item} pageId={pageId} />
          ))}
        </SmallCardListContainer>
      </FeaturedCardContainer>
    </ComponentWrapper>
  );
};

export default FeaturedArticle;
