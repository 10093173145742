import Link from 'components/Link';
import React from 'react';
import { FeatureCardType } from './type';
import {
  FeaturedCardDescriptionWrapper,
  FeaturedCardTitleWrapper,
  MobileContainer,
  WebContainer,
} from '../pages/newsroom/styles';
import tw from 'twin.macro';

interface FeaturedCardProps extends FeatureCardType {}

const StyledLink = tw(Link)`
  grid grid-cols-[2fr_3fr] lg:grid-cols-[1fr_2fr] gap-4 lg:gap-0 items-center hover:bg-hubble_blue
`;
const TextWrapper = tw.div`
  p-0 lg:py-3 lg:px-4
`;
const CardImg = tw.img`
  w-full lg:h-full self-center lg:self-start object-cover
`;

const FeaturedCard = ({
  title,
  description,
  slug,
  cover,
  pageId,
}: FeaturedCardProps) => {
  return (
    <StyledLink to={`/${pageId}/${slug}`} className="group">
      <CardImg src={cover.url} alt="featured card img" />

      <TextWrapper>
        <FeaturedCardTitleWrapper ellipsis={{ rows: 2 }}>
          {title}
        </FeaturedCardTitleWrapper>

        <WebContainer>
          <FeaturedCardDescriptionWrapper ellipsis={{ rows: 3 }}>
            {description}
          </FeaturedCardDescriptionWrapper>
        </WebContainer>
        <MobileContainer>
          <FeaturedCardDescriptionWrapper ellipsis={{ rows: 2 }}>
            {description}
          </FeaturedCardDescriptionWrapper>
        </MobileContainer>
      </TextWrapper>
    </StyledLink>
  );
};

export default FeaturedCard;
