import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { SORT } from './constant';
import FilterOnMobile from 'components/filter/FilterOnMobile';
import tw from 'twin.macro';
import ArticleCardV2 from 'components/articleList/ArticleCardV2';
import WebHorizontalFilter from 'components/filter/WebHorizontalFilter';
import ArticleListV2 from 'components/articleList/indexV2';
import useApi from 'utils/useApi';
import { buildPaginationParams, mergeParams } from 'utils/apiUtils';
import { LayoutTypes } from 'components/articleList/types';

const FormattedHeading = tw.p`
  text-[20px] text-base_text mb-4 lg:mb-6 px-[30px] lg:px-0 pt-10 md:text-center lg:text-start 
`;
const MobileFilterContainer = tw.div`
  block lg:hidden mt-4
`;

const NewsroomListOfArticles = () => {
  const { formatMessage } = useIntl();
  const [fetchParams, setFetchParams] = useState<Record<string, unknown>>(
    buildPaginationParams({
      sort: ['publishedAt:desc'],
      filters: {},
    }),
  );
  const { data: categoryData } = useApi<any>({
    path: `/categories`,
    autoFetchWithParams: {},
  });

  const filterListItems = !categoryData
    ? []
    : categoryData?.data?.map(({ attributes }: any) => {
        return {
          value: attributes.slug,
          label: attributes.name,
        };
      });
  const filterList = {
    key: 'category',
    label: 'Type',
    items: filterListItems,
  };

  const { data, loading, fetchData } = useApi({
    path: `/articles`,
    autoFetchWithParams: fetchParams,
  });

  const updateFilters = (params: Record<string, unknown>) => {
    const filterParams = mergeParams(fetchParams, params);
    setFetchParams(filterParams);
    fetchData(filterParams);
  };

  return (
    <div className="bg-white">
      <MobileFilterContainer>
        <FilterOnMobile
          sortList={SORT}
          filterList={[filterList]}
          updateFilters={updateFilters}
        />
      </MobileFilterContainer>

      <FormattedHeading>
        {formatMessage({ id: 'newsroom.articles' })}
      </FormattedHeading>

      <WebHorizontalFilter
        sortList={SORT}
        filterList={filterList.items}
        updateFilters={updateFilters}
      />

      <ArticleListV2
        renderListItem={(item, idx) => (
          <ArticleCardV2 key={idx} id="newsroom" {...item} />
        )}
        data={data}
        loading={loading}
        updateFilters={updateFilters}
        layoutType={LayoutTypes.THREE_COLS}
      />
    </div>
  );
};

export default NewsroomListOfArticles;
