import React, { useState } from 'react';
import { Input, Select } from 'antd';
import tw from 'twin.macro';
import { useIntl } from 'react-intl';
import { SortType } from './type';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import './style.css';
import { buildPaginationParams } from 'utils/apiUtils';

interface WebHorizontalFilterProps {
  sortList: SortType;
  filterList: SortType;
  updateFilters?: (params: Record<string, unknown>) => void;
}

const WebFilterContainer = tw.div`
  hidden lg:flex items-center justify-between
`;
const SortButtonWrapper = tw.div`
  flex items-center gap-1 text-[#9297A3] bg-accent_grey_2 rounded-[5px] pl-3 
`;
const SortButtonLabel = tw.span`
  text-[14px] cursor-pointer
`;

const { Search } = Input;

const WebHorizontalFilter = ({
  sortList,
  filterList,
  updateFilters,
}: WebHorizontalFilterProps) => {
  const { formatMessage } = useIntl();
  const [isSortOpen, setIsSortOpen] = useState<boolean>(false);
  const onSearch = (query: string) => {
    updateFilters?.(
      buildPaginationParams({
        filters: {
          title: {
            $containsi: query,
          },
        },
      }),
    );
  };

  const handleFilterType = (valueList: string[]) => {
    updateFilters?.(
      buildPaginationParams({
        filters: { category: { slug: { $in: valueList } } },
      }),
    );
  };

  const handleSortDate = (value: string) => {
    updateFilters?.(
      buildPaginationParams({
        sort: [`publishedAt:${value}`],
      }),
    );
  };

  return (
    <WebFilterContainer>
      <div>
        <Search
          placeholder={formatMessage({
            id: 'filter.search.placeholder',
          })}
          allowClear
          onSearch={onSearch}
          style={{ width: '290px', color: '#C1C3C9', marginRight: '10px' }}
          size="large"
        />
        <Select
          data-testid="web-horizontal-filter-select"
          style={{ minWidth: '290px' }}
          onChange={handleFilterType}
          options={filterList}
          // options={filterList.items}
          size="large"
          placeholder={formatMessage({
            id: 'filter.web.horizontal.filter.placeholder',
          })}
          mode="multiple"
        />
      </div>

      <SortButtonWrapper
        data-testid="web-horizontal-filter-sort"
        onClick={() => {
          setIsSortOpen(!isSortOpen);
        }}
      >
        <span className="fa-solid fa-sort text-[14px] cursor-pointer mr-3" />
        <SortButtonLabel>
          {formatMessage({
            id: 'filter.web.horizontal.sortBy',
          })}
        </SortButtonLabel>

        <Select
          className="filter-mobile-one-col-sort-select"
          data-testid="web-blog-select"
          style={{
            color: '#9297A3',
            background: '#EBEDF6',
            fontSize: '14px',
            borderRadius: '5px',
          }}
          onChange={handleSortDate}
          options={sortList.items}
          size="large"
          defaultValue={sortList.items[0].value}
          open={isSortOpen}
          showArrow={false}
          bordered={false}
          dropdownStyle={{ width: '190px' }}
          placement="bottomRight"
          popupClassName="newsroom-and-blog-web-sort"
        />
      </SortButtonWrapper>
    </WebFilterContainer>
  );
};

const index = (props: WebHorizontalFilterProps) => {
  return (
    <LanguageProvider messages={translation}>
      <WebHorizontalFilter {...props} />
    </LanguageProvider>
  );
};

export default index;
