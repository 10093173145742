import tw, { styled } from 'twin.macro';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export const FeaturedCardTitleWrapper = styled(Paragraph)`
  && {
    ${tw`text-[16px] mb-0 lg:mb-2 group-hover:text-white text-base_text `}
  }
`;
export const FeaturedCardDescriptionWrapper = styled(Paragraph)`
  && {
    ${tw`text-[14px] mb-0 lg:mb-2 text-[#7B7F8A] group-hover:text-white`}
  }
`;
export const WebContainer = tw.div`
  hidden lg:block
`;
export const MobileContainer = tw.div`
  lg:hidden
`;
