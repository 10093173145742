import React from 'react';
import RequestMediaKitButton from './RequestMediaKitButton';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const ComponentWrapper = tw.div`
    md:grid md:grid-cols-2 px-[30px] lg:px-0
`;

const ContentContainer = tw.div`
   p-4 md:px-12 md:py-10 bg-accent_grey_2 text-accent_blue_1 grid grid-cols-[1fr_2fr] md:grid-cols-1 gap-4
`;

const MobileImgItem = tw.span`
  block md:hidden
`;
const WebImgItem = tw.span`
  hidden md:block
`;

const TextWrapper = tw.div``;
const Title = tw.p`md:font-[500] mb-1 md:mb-0`;
const Description = tw.p`mb-4 md:mb-2`;

const NewsroomMediaKit = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiNewsroom {
        edges {
          node {
            MediaKitHeader
            MediaKitDescription
            MediaKitMobileImage {
              url
            }
            MediaKitWebImage {
              url
            }
          }
        }
      }
    }
  `);
  const {
    MediaKitHeader,
    MediaKitDescription,
    MediaKitMobileImage,
    MediaKitWebImage,
  } = data.allStrapiNewsroom.edges[0].node;
  return (
    <ComponentWrapper>
      <ContentContainer>
        <MobileImgItem
          style={{
            background: `url(${MediaKitMobileImage.url}) no-repeat center center /cover`,
          }}
          data-testid="newsroom-media-kit-mobile-img"
        />

        <TextWrapper>
          <Title className="b5">{MediaKitHeader}</Title>
          <Description className="b6">{MediaKitDescription}</Description>
          <RequestMediaKitButton />
        </TextWrapper>
      </ContentContainer>

      <WebImgItem
        style={{
          background: `url(${MediaKitWebImage.url}) no-repeat center center /cover`,
        }}
        data-testid="newsroom-media-kit-web-img"
      />
    </ComponentWrapper>
  );
};

export default NewsroomMediaKit;
