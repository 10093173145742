import LanguageProvider from 'LanguageProvider/index';
import React from 'react';
import NewsroomFeaturedArticle from '../../featuredArticles';
import NewsroomMediaKit from './mediaKit';
import NewsroomListOfArticles from './NewsroomListOfArticles';
import NewsroomHero from './NewsroomHero';
import translation from './translation';
import './style.css';
import useApi from 'utils/useApi';
import LoadingSpin from 'components/LoadingSpin';
import { buildPaginationParams } from 'utils/apiUtils';

const NewsroomIndex = () => {
  const { data: featuredList, loading } = useApi<any>({
    path: '/articles/featured',
    autoFetchWithParams: buildPaginationParams({
      sort: ['publishedAt:desc'],
      pagination: {
        pageSize: 4,
      },
    }),
  });

  return (
    <LanguageProvider messages={translation}>
      <NewsroomHero />
      {loading || featuredList === null ? (
        <LoadingSpin />
      ) : (
        <NewsroomFeaturedArticle
          cardList={featuredList?.data}
          pageId="newsroom"
        />
      )}
      <NewsroomMediaKit />
      <NewsroomListOfArticles />
    </LanguageProvider>
  );
};

export default NewsroomIndex;
