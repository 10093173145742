import Link from 'components/Link';
import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import {
  FeaturedCardDescriptionWrapper,
  FeaturedCardTitleWrapper,
  MobileContainer,
  WebContainer,
} from '../pages/newsroom/styles';
import { FeatureCardType } from './type';

interface LargestFeaturedCardProps extends FeatureCardType {}

const StyledLink = tw(Link)`
  shadow-[0px_1px_5px_rgba(41,45,55,0.15)] h-[500px] flex flex-col
`;
const ContentContainer = tw.div`
  px-4 py-5 max-w-[650px] bg-white group-hover:bg-hubble_blue flex flex-col gap-2 lg:gap-0
`;
const ExpandedItem = tw.span`
  flex-1
`;
const ButtonContainer = tw.div`
  flex items-center gap-2 text-white bg-accent_blue_2 w-fit px-3 py-2 hover:text-white hover:bg-hubble_blue 
`;
const ButtonLabel = tw.span`
  text-[14px]
`;

const LargestFeaturedCard = ({
  title,
  description,
  slug,
  cover,
  pageId,
}: LargestFeaturedCardProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledLink
      to={`/${pageId}/${slug}`}
      className="group"
      style={{
        background: `url(${cover.url}) no-repeat center center /cover`,
      }}
    >
      <ExpandedItem />

      <ContentContainer>
        <WebContainer>
          <FeaturedCardTitleWrapper ellipsis={{ rows: 1 }}>
            {title}
          </FeaturedCardTitleWrapper>
          <FeaturedCardDescriptionWrapper ellipsis={{ rows: 2 }}>
            {description}
          </FeaturedCardDescriptionWrapper>
        </WebContainer>

        <MobileContainer>
          <FeaturedCardTitleWrapper ellipsis={{ rows: 2 }}>
            {title}
          </FeaturedCardTitleWrapper>
          <FeaturedCardDescriptionWrapper ellipsis={{ rows: 4 }}>
            {description}
          </FeaturedCardDescriptionWrapper>
        </MobileContainer>

        <ButtonContainer>
          <ButtonLabel>
            {formatMessage({ id: 'featuredArticle.button.readMore' })}
          </ButtonLabel>
          <span className="fa-light fa-arrow-right" />
        </ButtonContainer>
      </ContentContainer>
    </StyledLink>
  );
};

export default LargestFeaturedCard;
