import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import documentIcon from 'images/newsroom/document-icon.svg';
import tw from 'twin.macro';
import HubspotForm from 'components/HubspotForm';
import { NotificationTypes } from 'components/types';
import useNotification from 'components/notification/useNotification';
import { graphql, useStaticQuery } from 'gatsby';

const ControlModalButton = tw.button`
  block w-fit border border-hubble_blue bg-white px-4 py-2 text-hubble_blue hover:text-white hover:bg-hubble_blue
`;
const Heading = tw.h4`
  text-hubble_blue m-0
`;
const SubHeading = tw.p`
  text-subtext m-0
`;
const ModalHeaderWrapper = tw.div`
  flex gap-4 items-center justify-start mb-6
`;
const IconImg = tw.img`
  h-16 w-16 lg:h-20 lg:w-20
`;

const RequestMediaKitButton = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiNewsroom {
        edges {
          node {
            MediaKitCTAButtonLabel
            PopUpBoxHeader
            PopUpBoxDescription
          }
        }
      }
    }
  `);
  const { MediaKitCTAButtonLabel, PopUpBoxHeader, PopUpBoxDescription } =
    data.allStrapiNewsroom.edges[0].node;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { showNotification } = useNotification({
    type: NotificationTypes.FORM,
    key: `request-media-kit`,
  });

  useEffect(() => {
    if (isSubmitted) {
      showNotification();
    }
  }, [isSubmitted]);

  return (
    <>
      <ControlModalButton onClick={showModal}>
        {MediaKitCTAButtonLabel}
      </ControlModalButton>
      <Modal
        title={null}
        open={isModalOpen}
        bodyStyle={{ padding: 0 }}
        onCancel={handleClose}
        footer={null}
      >
        <div className="p-6 md:p-9">
          <ModalHeaderWrapper>
            <IconImg src={documentIcon} alt="download icon" />
            <div>
              <Heading>{PopUpBoxHeader}</Heading>
              <SubHeading className="b6 ">{PopUpBoxDescription}</SubHeading>
            </div>
          </ModalHeaderWrapper>

          <HubspotForm
            formId="8be91983-9552-4f02-bb66-30d1497ef4de"
            onFormSubmitted={() => {
              setIsModalOpen(false);
              setIsSubmitted(true);
            }}
            onFormReady={() => {
              if (isSubmitted) setIsSubmitted(false);
            }}
            formTarget="request-media-kit"
          />
        </div>
      </Modal>
    </>
  );
};

export default RequestMediaKitButton;
