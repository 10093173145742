import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const NewsroomHeroWrapper = tw.div`
  px-[30px] lg:px-0 text-base_text h-[238px] flex flex-col justify-center items-center lg:justify-end lg:items-start mt-[84px]
`;

const Heading = tw.h3`
  text-[36px] md:text-[48px] m-0
`;

const SubHeading = tw.h1`mb-10`;

const NewsroomHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiNewsroom {
        edges {
          node {
            PageHeader
            PageDescription
          }
        }
      }
    }
  `);
  const { PageHeader, PageDescription } = data.allStrapiNewsroom.edges[0].node;
  return (
    <NewsroomHeroWrapper>
      <Heading> {PageHeader}</Heading>
      <SubHeading className="b5">{PageDescription}</SubHeading>
    </NewsroomHeroWrapper>
  );
};

export default NewsroomHero;
