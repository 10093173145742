import { SortType } from 'components/filter/type';

export const SORT: SortType = {
  key: 'sort',
  label: 'Sort',
  items: [
    { key: 'latest', value: 'desc', label: 'Latest' },
    { key: 'oldest', value: 'asc', label: 'Oldest' },
  ],
};
