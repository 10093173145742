import * as React from 'react';
import Layout from 'components/Layout';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import NewsroomIndex from 'components/pages/newsroom';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/newsroom`;

const NewsroomPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <Layout headerStyle="light">
        <BreadcrumbList
          pageLabelId="structuredData.newsroom"
          pageUrl={pageUrl}
        />
        <NewsroomIndex />
      </Layout>
    </LanguageProvider>
  );
};
export default NewsroomPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiNewsroom: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL data={data.allStrapiNewsroom.nodes[0]} canonicalURL={pageUrl} />
  );
};

export const query = graphql`
  query {
    allStrapiNewsroom {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description

            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
