import React from 'react';
import { FeatureCardType } from './type';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import FeaturedArticle from './FeaturedArticle';

interface FeaturedArticleProps {
  cardList: FeatureCardType[];
  pageId: string;
}

const FeaturedArticleIndex = (props: FeaturedArticleProps) => {
  return (
    <LanguageProvider messages={translation}>
      <FeaturedArticle {...props} />
    </LanguageProvider>
  );
};

export default FeaturedArticleIndex;
